
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import type { PropType } from 'vue'
import type { StatusType } from '@/definitions/shared/types'
import { capitalizeFirstLetter } from '@/services/string'

export default defineComponent({
  components: {
    TmStatus,
    TmFormLine,
  },
  props: {
    selectedStatus: {
      type: Object as PropType<StatusType>,
    },
    name: {
      type: String,
      default: '',
    },
    initProbability: {
      type: String,
      default: '',
    },
    system: {
      type: Boolean,
    },
  },
  setup(props) {
    const statusText = ref(capitalizeFirstLetter(props.name))
    const probability = ref(props.initProbability)

    return {
      probability,
      statusText,
    }
  },
})
