import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_deals_settings_stage_panel = _resolveComponent("deals-settings-stage-panel")!
  const _component_deals_settings_stage_panel_preview = _resolveComponent("deals-settings-stage-panel-preview")!
  const _component_statuses_list = _resolveComponent("statuses-list")!
  const _component_white_block = _resolveComponent("white-block")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, {
    breadcrumbs: _ctx.breadcrumbs,
    size: "large",
    "bg-shade": ""
  }, {
    "filter-bar-dropdown": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-dropdown")
    ]),
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-top-right": _withCtx(() => [
      _renderSlot(_ctx.$slots, "page-content-top-right")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Stages",
        icon: "flag",
        class: "mb-5"
      }),
      _createVNode(_component_white_block, null, {
        default: _withCtx(() => [
          _createVNode(_component_statuses_list, {
            "delete-modal-title": "Delete contact status",
            "item-name": "Pipeline",
            "status-name": "stage",
            class: "px-8",
            "palette-label": "Stage color",
            "palette-items": _ctx.colorPaletteOptionsOutline,
            "status-name-plenty": "stages",
            statuses: _ctx.statuses,
            "onUpdate:statuses": _cache[0] || (_cache[0] = ($event: any) => (_ctx.statuses = $event))
          }, {
            default: _withCtx(({ item }) => [
              _createVNode(_component_deals_settings_stage_panel, {
                item: item,
                "palette-items": _ctx.colorPaletteOptionsOutline,
                system: item.isDefault,
                statuses: _ctx.statuses
              }, null, 8, ["item", "palette-items", "system", "statuses"])
            ]),
            preview: _withCtx(({ selectedStatus }) => [
              _createVNode(_component_deals_settings_stage_panel_preview, { "selected-status": selectedStatus }, null, 8, ["selected-status"])
            ]),
            _: 1
          }, 8, ["palette-items", "statuses"])
        ]),
        _: 1
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}