
import { defineComponent, ref } from 'vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import WhiteBlock from '@/components/shared/templates/WhiteBlock.vue'
import StatusesList from '@/components/pages/shared/StatusesList.vue'
import type { BreadcrumbsLink } from '@/definitions/shared/types'
import { colorPaletteOptionsOutline } from '@/definitions/_general/_data/colorPaletteOptions'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import { dealsStatuses } from '@/definitions/deals/data'
import DealsSettingsStagePanel from '@/components/pages/deals/settings/DealsSettingsStagePanel.vue'
import DealsSettingsStagePanelPreview from '@/components/pages/deals/settings/DealsSettingsStagePanelPreview.vue'

export default defineComponent({
  components: {
    DealsSettingsStagePanel,
    DealsSettingsStagePanelPreview,
    PageContent,
    WhiteBlock,
    DetailsHero,
    StatusesList,
  },
  setup() {
    const breadcrumbs: BreadcrumbsLink[] = [
      {
        label: 'Deals',
        name: 'base.deals.index',
      },
      {
        label: 'Pipelines',
        name: 'base.deals.settings',
      },
      {
        label: 'Sales US',
        name: 'base.deals.settings.detailed',
      },
      {
        label: 'Stages',
      },
    ]
    const statuses = ref(dealsStatuses)

    return {
      breadcrumbs,
      statuses,
      colorPaletteOptionsOutline,
    }
  },
})
