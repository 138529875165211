import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex-grow-1" }
const _hoisted_2 = { class: "d-flex align-center" }
const _hoisted_3 = { class: "mr-8 body-text-semi-bold-14 lh-20 neutral--text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_color_indicator = _resolveComponent("tm-color-indicator")!
  const _component_deals_settings_stage_panel_preview = _resolveComponent("deals-settings-stage-panel-preview")!
  const _component_status_panel = _resolveComponent("status-panel")!

  return (_openBlock(), _createBlock(_component_status_panel, {
    item: _ctx.item,
    editable: "",
    draggable: !_ctx.system,
    "hide-delete": _ctx.system,
    "hide-palette": _ctx.system,
    "status-name": "pipeline stage",
    "delete-status-function": () => _ctx.deletePipelineStage(_ctx.item.name)
  }, {
    preview: _withCtx(({ selectedStatus }) => [
      _createVNode(_component_deals_settings_stage_panel_preview, {
        name: _ctx.item.name,
        system: _ctx.system,
        "init-probability": _ctx.item.probability,
        "selected-status": selectedStatus
      }, null, 8, ["name", "system", "init-probability", "selected-status"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_status, {
          name: _ctx.item.name,
          outline: _ctx.item.outline,
          color: _ctx.item.color
        }, null, 8, ["name", "outline", "color"]),
        (_ctx.system)
          ? (_openBlock(), _createBlock(_component_tm_status, {
              key: 0,
              class: "ml-2",
              name: "System",
              color: "gray",
              outline: true
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.item.probability), 1),
        _createVNode(_component_tm_color_indicator, {
          hoverable: false,
          outline: _ctx.item.outline,
          color: _ctx.item.color
        }, null, 8, ["outline", "color"])
      ])
    ]),
    _: 1
  }, 8, ["item", "draggable", "hide-delete", "hide-palette", "delete-status-function"]))
}